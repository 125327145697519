<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton btnTitle="List" :showBtn="true" :showAddNew="false" title="Purchase Bill Return"
                   @onClickCloseButton="navigateToListPage"/>

      <div class="row mt-2 gy-1">
        <div class="col-md-4">
          <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                 tabindex="1" type="date" placeholder="" v-model="formData.date" readonly>
        </div>
        <div class="col-md-4">
          <v-select placeholder="Select Business" v-model="formData.business_id" :options="business"
                    label="name" :reduce="name => name.id" disabled/>
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-between">
          <AsyncSelect placeholder="Select Contact" v-model="contactProfile"
                       :api-service="fetchContactProfiles" :format-label="formatPatientLabel" class="flex-grow-1"
                       :additional-query="{ type: 'supplier' }" disabled/>
          <span class="filter-search-icon cursor-pointer mx-1" @click="onOpenContactSearchModal">
                        <i class="fas fa-search"></i>
                    </span>
        </div>
        <div class="col-md-4">
          <v-select placeholder="Account Head" v-model="formData.account_head_id" :options="accountPayable"
                    label="name" :reduce="name => name.id" disabled/>
        </div>

        <div class="col-md-4">
          <v-select placeholder="Select Warehouse Location" v-model="formData.location_id"
                    :options="locations" label="text" :reduce="text => text.id" disabled/>
        </div>
        <div class="col-md-4">
          <div class="d-flex align-items-center justify-content-md-end mb-1">
            <div class="input-group input-group-merge invoice-edit-input-group">
              <div class="input-group-text" style="background: #EFEFEF">
                <span>{{ prefix }}-</span>
              </div>
              <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                     v-model="serial" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3 py-3">
      <ProductsTable
        :itemDetails="formData.item_details"
        @removeBill="removeBill"
        :formRef="productForm"
        :is-partial="isPartial"
      />
    </div>

    <div class="px-2">
      <div class="row justify-content-end">
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="first-name">Sub total</label>
                </div>
                <div class="col-sm-8">
                  <input v-model="subTotal" readonly type="text" class="form-control text-right">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="first-name">VAT</label>
                </div>
                <div class="col-sm-8">
                  <input v-model="totalVAT" readonly type="text" class="form-control text-right">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="first-name">Total Amount</label>
                </div>
                <div class="col-sm-8">
                  <input v-model="total" readonly type="text" class="form-control text-right">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="discount-amount">Adjustment Amount</label>
                </div>
                <div class="col-sm-8">
                  <input
                    id="discount-amount"
                    v-model.trim="discountAmount"
                    type="number"
                    class="form-control form-control-sm discount-adj ml-auto text-right"
                    placeholder="amount"
                    :min="0"
                    @keyup="updateGrandTotal"
                    readonly
                  >
                </div>
              </div>
            </div>
            <div class="col-12" v-if="discountAmount > 0">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="discount-amount">Settlement Account</label>
                </div>
                <div class="col-sm-8">
                  <v-select
                    placeholder="Select Discount Head"
                    v-model="formData.discount_head_id"
                    :options="salesAccountHeads"
                    label="name"
                    :reduce="name => name.id"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="discount-amount">Net Bill</label>
                </div>
                <div class="col-sm-8">
                  <input
                    id="discount-amount"
                    v-model.trim="grandTotal"
                    type="number"
                    class="form-control form-control-sm discount-adj ml-auto text-right"
                    placeholder="amount"
                    :min="0"
                    @keyup="updateDiscount"
                    readonly
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 px-2">
      <div class="mb-1">
        <label class="form-label" for="description">Memo</label>
        <vField as="textarea" name="description" type="number" class="form-control" v-model="formData.description"/>
      </div>
    </div>

    <div class="pb-5 px-2 mt-4">
      <div class="d-flex flex-wrap gap-1 gy-2">
        <button :disabled="saveButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & Print
        </button>
        <button :disabled="saveButtonLoader" @click="navigateToListPage"
                class="btn btn-outline-secondary">Cancel
        </button>
      </div>
    </div>
    <GlobalLoader/>
    <ContactDropdownFilter v-if="store.state.isModalOpenThree" @onSearchContact="onSearchContact" type="supplier"
                           :companyRoles="companyRoles"/>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'

import {editVoucherNumber, generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import {useStore} from "vuex";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import handleRole from "@/services/modules/role";
import ProductsTable from "./partials/ProductsTable.vue"
import useDate from '@/services/utils/day'
import handleBarcode from "@/services/modules/barcode";
import pdfPrinter from '@/services/utils/pbPdfPrinter';
import handleCompany from "@/services/modules/company";
import handlePurchaseAndSalesReturn from "@/services/modules/purchaseSalesReturn";

const router = useRouter()
const route = useRoute()
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchAccountHead, ...rest} = handlePurchase()
const {storePurchasesBill, fetchPurchaseReturnBill} = handlePurchaseAndSalesReturn()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyDefaultRoles} = handleRole();
const {fetchBarcode} = handleBarcode();
const {formatDate} = useDate()
const {exportToPDF} = pdfPrinter();
const {fetchCompanyInfo} = handleCompany();

const companyId = computed(() => {
  return route.params.companyId
})

const isPartial = computed(() => {
  return route.name === 'purchase-bill-partial-return'
})

const loader = ref(false)
const saveButtonLoader = ref(false)
const accountHeads = ref([])
const business = ref([])
const locations = ref([])
const salesAccountHeads = ref([])
const accountPayable = ref([])
const prefix = ref('')
const serial = ref('')
const companyInfo = ref({});
const discountAmount = ref(null);
const contactProfile = ref(null);
const productForm = ref(null);
const companyRoles = ref([]);
const grandTotal = ref(0);
const formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  business_id: null,
  location_id: null,
  bill_number: '',
  status: 'active',
  date: '',
  has_item_detail: true,
  description: '',
  item_details: [],
})

onMounted(async () => {
  loader.value = true
  const companyQuery = `?company_id=${companyId.value}`

  const accountHeadRes = fetchAccountHead(companyQuery)
  const businessRes = fetchBusinessList(companyQuery)
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)
  const payableRes = rest.fetchAccountPayable(companyQuery)
  const salesAccountHeadsRes = rest.getAccountHeadBySlag("discount_receipt", companyQuery)
  Promise.all([
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data
    }),
    businessRes.then(res => {
      if (res.data) business.value = res.data
    }),
    businessLocationRes.then(res => {
      if (res.data) locations.value = res.data
    }),
    fetchCompanyDefaultRoles(companyQuery).then(res => {
      if (res.data) companyRoles.value = res.data
    }),
    payableRes.then(res => {
      if (res.data) {
        accountPayable.value = res.data
      }
      if (res.data?.length) {
        formData.value.account_head_id = res.data[0].id
      }
    }),
    salesAccountHeadsRes.then(res => {
      if (res.data) salesAccountHeads.value = res.data
      formData.value.discount_head_id = res.data[0] ? res.data[0].id : null
    }),
    fetchCompanyInfo(companyId.value).then(res => {
      if (res) {
        companyInfo.value = res.data
      }
    }),
    rest.fetchPurchaseBill(route.params.billId, companyQuery).then(res => {
      if (res.data) {
        const voucher = editVoucherNumber(res.data.bill_number);
        prefix.value = voucher.prefix;
        serial.value = voucher.serial;
        contactProfile.value = res.data.contact;
        discountAmount.value = res.data.adjustment_amount;
        formData.value = {
          company_id: res.data.company_id,
          contact_profile_id: res.data.contact_profile_id,
          account_head_id: res.data.account_head_id,
          business_id: res.data.business_id,
          location_id: res.data.location_id,
          bill_number: res.data.bill_number,
          purchase_master_id: res.data.id,
          adjustment_amount: res.data.adjustment_amount,
          discount_head_id: res.data.discount_head_id,
          status: res.data.status,
          date: res.data.date,
          has_item_detail: true,
          description: res.data.description,
          item_details: res.data.general.map(({id, ...item}) => {
            return {
              ...item,
              purchase_general_id: id,
              product_name: item.product.name,
              g_total: item.total_amount,
              stock_register_id: item.stock_register_id,
            }
          }),
        }
      }
    })
  ])
    .then(() => {
      loader.value = false
    })
    .catch(() => {
      loader.value = false
    })
})

const isItem = computed(() => {
  return formData.value.has_item_detail
})

const subTotal = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseFloat((item.quantity * item.rate) - (item.discount_amount ?? 0))
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseFloat(item.amount)
      }
    })
  }

  return subTotal.toFixed(4)
})

const totalVAT = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }

  return vat.toFixed(4)
})

const total = computed(() => {
  return (parseFloat(subTotal.value) + parseFloat(totalVAT.value)).toFixed(4)
})

const updateGrandTotal = () => {
  grandTotal.value = (parseFloat(total.value) - parseFloat(discountAmount.value)).toFixed(4);
}

const updateDiscount = () => {
  discountAmount.value = (parseFloat(total.value) - grandTotal.value).toFixed(4);
}

watch(discountAmount, () => {
  formData.value.adjustment_amount = parseFloat(discountAmount.value)
})

watch(total, () => {
  if (discountAmount.value && discountAmount.value !== "") {
    grandTotal.value = (parseFloat(total.value) - parseFloat(discountAmount.value)).toFixed(4);
    return;
  }
  grandTotal.value = parseFloat(total.value).toFixed(4);
})

watch(contactProfile, (newVal) => {
  if (!newVal) {
    formData.value.contact_profile_id = null;
    return;
  }

  formData.value.contact_profile_id = newVal.id;
})

function navigateToListPage() {
  delete route.params.billId;

  router.push({name: 'purchase-bill-manager', params: route.params, query: route.query})
}

function onOpenContactSearchModal() {
  store.state.isModalOpenThree = true;
}

function onSearchContact(contact) {
  contactProfile.value = contact
}

function removeBill(index) {
  formData.value.item_details.splice(index, 1)
}

const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;
  let barcodeQuery = "";
  let qrcode = null
  let barcode = null
  let purchaseBill = null

  fetchPurchaseReturnBill(id, companyQuery).then((res) => {
    if (res) {
      purchaseBill = res.data;
      let qrcodeData = JSON.stringify({
        ref_no: purchaseBill.bill_number,
        ref_date: purchaseBill.date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery = `?code=${purchaseBill.bill_number}&qrcode=${qrcodeData}`;
    }
  }).then(() => {
    fetchBarcode(barcodeQuery).then((res) => {
      barcode = res.barcode;
      qrcode = res.qrcode
    }).then(() => {
      exportToPDF(companyInfo.value, purchaseBill, barcode, qrcode, user.name, 1, true)
      navigateToListPage()
    })
  }).catch((err) => {
    console.log(err);
  })
}

function handleSubmit() {
  saveButtonLoader.value = true

  let copyFormData = JSON.parse(JSON.stringify(formData.value))
  let isAllItemsReturned = !copyFormData.item_details.find(
                              item => parseInt(item.return_qty) !== (item.quantity - item.prev_return_qty)
                            );

  copyFormData.has_item_detail = 1
  copyFormData.status = "active";
  copyFormData.purchase_bill_status = (isPartial.value && ! isAllItemsReturned) ? "partially_returned" : "returned";
  delete copyFormData.bill_number;

  if (isItem.value) {
    delete copyFormData.account_details
    copyFormData.item_details = formData.value.item_details.map(item => {
      return {
        ...item,
        quantity: isPartial.value ? item.return_qty : item.quantity
      }
    })
  }

  storePurchasesBill(copyFormData)
    .then(res => {
      if (res.status) {
        showSuccess(res.message)
        goToPrint(res.data.id)
      }
      if (!res.status) {
        showError(res.message)
        saveButtonLoader.value = false
      }
    })
    .catch((err) => {
      saveButtonLoader.value = false
      console.log(err)
    })
}

</script>
